<template>
  <el-dialog
      :show-close="false"
      :visible.sync="data"
      append-to-body
      custom-class="dialogVisibleContainer"
      width="1518px">
    <header>
      <div class="year">
        <img alt="" src="../../../assets/homePage/index/leftArrowsIcon.png" @click="switchYear(1)">
        <div>{{ year }}</div>
        <img alt="" src="../../../assets/homePage/index/rightArrowsIcon.png" @click="switchYear(2)">
      </div>
      <img class="closeIcon" src="../../../assets/homePage/index/closeIcon.png" @click="$emit('close','')"/>
    </header>
    <footer>
      <div class="row" style="margin-bottom: 20px">
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>1月</div>
            <div>JANUARY</div>
          </div>
          <el-calendar v-model="JANUARY"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>2月</div>
            <div>FEBRUARY</div>
          </div>
          <el-calendar v-model="FEBRUARY"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>3月</div>
            <div>MARCH</div>
          </div>
          <el-calendar v-model="MARCH"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>4月</div>
            <div>APRIL</div>
          </div>
          <el-calendar v-model="APRIL"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px">
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>5月</div>
            <div>MAY</div>
          </div>
          <el-calendar v-model="MAY"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>6月</div>
            <div>JUNE</div>
          </div>
          <el-calendar v-model="JUNE"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>7月</div>
            <div>JULY</div>
          </div>
          <el-calendar v-model="JULY"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>8月</div>
            <div>AUGUST</div>
          </div>
          <el-calendar v-model="AUGUST"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
      </div>
      <div class="row">
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>9月</div>
            <div>SEPTEMBER</div>
          </div>
          <el-calendar v-model="SEPTEMBER"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>10月</div>
            <div>OCTOBER</div>
          </div>
          <el-calendar v-model="OCTOBER"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>11月</div>
            <div>NOVEMBER</div>
          </div>
          <el-calendar v-model="NOVEMBER"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
        <div class="calendarItem">
          <div class="newCalendarHeader">
            <div>12月</div>
            <div>DECEMBER</div>
          </div>
          <el-calendar v-model="DECEMBER"
                       :first-day-of-week="1">
            <template
                slot="dateCell"
                slot-scope="{date, data}">
              <div v-if="dataMatching(data.day)" class="showDateBox">
                <el-popover v-if="dataMatching(data.day).remark!=null" placement="bottom" trigger="hover"
                            width="400">
                  <div v-if="dataMatching(data.day).remark">
                    <p style="font-size: 18px">
                      {{ dataMatching(data.day).remark.title }}
                    </p>
                    <p style="white-space: pre-wrap;" v-html="dataMatching(data.day).remark.description"></p>
                  </div>
                  <div
                      slot="reference"
                      :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                      :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                      class="backgroundBox">
                    {{ data.day.substr(8, 2) }}
                  </div>
                </el-popover>
                <div
                    v-else
                    :class="{ haveMethods: dataMatching(data.day).is_holiday }"
                    :style="`background:${dataMatching(data.day).is_holiday? dataMatching(data.day).color: ''}`"
                    class="backgroundBox">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
              <div v-else class="showDateBox" style="pointer-events: none">
                <div class="backgroundBox" style="pointer-events: none">
                  {{ data.day.substr(8, 2) }}
                </div>
              </div>
            </template>
          </el-calendar>
        </div>
      </div>
    </footer>
    <div class="calendarFooter">
      <div class="flexAndCenter">
        <div style="background: #B4272D;"></div>
        <div>Rakumart休日</div>
      </div>
      <div class="flexAndCenter">
        <div style="background: #FFD700;"></div>
        <div>振替出勤</div>
      </div>
      <div class="flexAndCenter">
        <div style="background: #1E90FF;"></div>
        <div>日本祝日</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    data: {
      type: Boolean,
    },
    nowadayYear: {
      type: Number
    },
    list: {
      type: Array
    },
  },
  data() {
    return {
      year: this.nowadayYear,
      calendarList: this.list,
      JANUARY: '',
      FEBRUARY: '',
      MARCH: '',
      APRIL: '',
      MAY: '',
      JUNE: '',
      JULY: '',
      AUGUST: '',
      SEPTEMBER: '',
      OCTOBER: '',
      NOVEMBER: '',
      DECEMBER: '',
      selectYear: []
    }
  },
  mounted() {
    this.updateMonth();
    this.selectYear.push(this.year)
  },
  methods: {
    switchYear(type) {
      type == 1 ? this.year-- : this.year++;
      if (this.selectYear.indexOf(this.year) == -1) {
        this.$api.getCalendar({year: this.year}).then((res) => {
          res.data.forEach((item) => {
            this.calendarList.push(item)
          })
          this.updateMonth();
        })
      } else {
        this.updateMonth();
      }
    },
    updateMonth() {
      this.JANUARY = this.year + '-01-01';
      this.FEBRUARY = this.year + '-02-01';
      this.MARCH = this.year + '-03-01';
      this.APRIL = this.year + '-04-01';
      this.MAY = this.year + '-05-01';
      this.JUNE = this.year + '-06-01';
      this.JULY = this.year + '-07-01';
      this.AUGUST = this.year + '-08-01';
      this.SEPTEMBER = this.year + '-09-01';
      this.OCTOBER = this.year + '-10-01';
      this.NOVEMBER = this.year + '-11-01';
      this.DECEMBER = this.year + '-12-01';
    },
    dataMatching(v) {
      let data = this.calendarList.find((item) => {
        return item.date == v;
      });
      return data;
    },
  }
}

</script>

<style lang="scss" scoped>
header {
  border-radius: 6px 6px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg, #A01E22, #B4272B);
  height: 80px;
  padding: 0 40px 0 345px;

  .closeIcon {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 274px;
    width: 772px;

    img {
      width: 27px;
      height: 49px;
      cursor: pointer;
    }

    div {
      color: #fff;
      font-style: italic;
      font-weight: bold;
      font-size: 72px;
    }
  }
}

footer {
  padding: 25px 30px;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .calendarItem {
      position: relative;

      .newCalendarHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        div:first-child {
          color: #B4272B;
          font-weight: bold;
        }

        div:last-child {
          font-style: italic;
          color: #000000;
          font-weight: bold;
          font-size: 14px;
        }
      }

      /deep/ .el-calendar {
        width: 322px;
        border-radius: 6px;

        .el-calendar__header {
          display: none;
        }

        .el-calendar__body {
          padding: 0;
        }

        .el-calendar__header .el-calendar__button-group {
          display: none;
        }

        thead {
          height: 30px;
          background: #D7D7D7;
          border-radius: 4px;
          overflow: hidden;

        }

        .el-calendar-day {
          height: 25px;
          line-height: 25px;
          text-align: center;
          padding: 0;

          //border-radius: 4px;
        }

        .el-calendar-table thead th {
          text-align: center;
          padding: 0;
        }

        .backgroundBox {
          display: flex;
          justify-content: center;
          align-items: center;
          //border-radius: 4px;
        }

        .backgroundBox.haveMethods {
          background-color: #b4272d;
          color: white;
        }

        .el-calendar-table tr td:first-child, .el-calendar-table tr:first-child td, .el-calendar-table td {
          border: none;
        }
      }

      /deep/ .el-calendar-table:not(.is-range) td.next {
        pointer-events: none;
      }

      /deep/ .el-calendar-table:not(.is-range) td.prev {
        pointer-events: none;
      }
    }
  }
}

.calendarFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  .flexAndCenter:first-child {
    margin-left: 0;
  }

  .flexAndCenter {
    margin-left: 23px;

    div:first-child {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-right: 6px;
    }

    div:last-child {
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>